<template>
  <v-container class="mt-10 pa-xs-2 bl_home">
  <v-row class="d-flex justify-center align-center">
    <v-col cols="12" lg="10" class="my-4 my-lg-1 d-flex justify-center align-center">
      <p class="text-h6 text-lg-h5 text-center primary--text" style="letter-spacing: 0.01em;">
        <span class="text-no-wrap">住宅トップランナー制度において、</span>
        <span class="text-no-wrap">建築した住宅のエネルギー</span>
        <span class="text-no-wrap">消費性能算定結果を</span>
        <span class="text-no-wrap">報告する際の事前チェックを</span>
        <span class="text-no-wrap">行うことができます。</span>
      </p>
    </v-col>
    <v-col cols="12" style="min-height: 300px; ">
      <v-carousel
        cycle
        hide-delimiter-background
        show-arrows-on-hover
        height=330
        dark
      >
        <v-carousel-item
          v-for="(slideItem,i) in slideItems"
          :key="i"
          :to="slideItem.to"
          transition="fade-transition"
          :src="slideItem.src"
          contain
        >
        </v-carousel-item>
      </v-carousel>
    </v-col>
    

    <v-col cols="12" class="my-6 d-flex justify-center" style="column-gap:50px;">
      <a href="/document/toprunner_filecheck_manual.pdf" target="_blank" class="primary--text el_btn__dl">使い方
        <v-icon
          small
          color="primary"
        >
          mdi-file-pdf-box
        </v-icon>
      </a>
      <router-link to="/faq" target="_blank" class="primary--text el_btn__dl">よくある質問
        <v-icon
          small
          color="primary"
        >
          mdi-open-in-new
        </v-icon>
      </router-link>
    </v-col>
    
    <v-col cols="12" class="mb-12 d-flex justify-center">
      <v-btn
      to="/terms"
      width="250"
      height="75"
      color="#5E539A">
        <span class="text-h5 white--text">はじめる</span>
      </v-btn>
    </v-col>
  </v-row>
  </v-container>
</template>

<script>
export default {

  data() {
    return {
      slideItems: [
          {
          src: "../img/tr_slide_03_2x.png",
          to: "/news"
          },
          {
          src: "../img/tr_slide_02_2x.png",
          to: "/news#yakuwari"
          },
          {
          src: "../img/tr_slide_01_2x.png",
          to: "/news"
          },
        ],
    }
  }
}
</script>

<style scoped>
.bl_home >>> .v-window .v-window__container{
  overflow: hidden !important;
}
.bl_home >>> .el_btn__dl{
  font-size: 1.2em;
}

/* スマホ表示時のみ */
@media (max-width: 1260px){
.bl_home >>> .v-carousel .v-carousel__controls__item.v-btn{
  color: #ccc;
}
}
</style>